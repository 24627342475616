import React from 'react';
import Layout from 'templates/Layout/Layout';
import PageHeading from 'components/_shared/PageHeading/PageHeading';
import ContentData from 'content/404-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import AloneShapes from 'components/_shared/AloneShapes/AloneShapes';

const Page = () => (
  <Layout metaTitle="Nie znaleziono strony" metaDescription="Strona nie istnieje">
    <PageHeading text="404 - Strona której szukasz nie istnieje" />
    <AloneShapes />
    <InformationOffice contentData={ContentData} />
  </Layout>
);

export default Page;
