import React from 'react';
import loadable from '@loadable/component';
import { colors } from 'utils/variables';
import { StyledWrapper, StyledContainer, StyledShape1, StyledShape2 } from './AloneShapes.styled';
import StaticShape from './StaticShape/StaticShape';

const AnimatedShape =  loadable(() => import('./AnimatedShape/AnimatedShape'));

const AloneShapes = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <StaticShape
          name="AloneShapes-1"
          originalWidth={465}
          originalHeight={345}
          path=" M 0.001 11.001 C 0.033 7.474 1.944 4.274 2.941 3.001 C 4.338 0.902 6.324 -0.045 8.18 0.002 C 10.176 0.052 13.869 0.052 16.364 0.002 C 20.157 0.552 21.204 1.451 22.254 2.751 C 23.35 3.601 23.345 5.22 23.151 6.651 C 23.001 7.751 20.954 10.181 20.556 10.601 C 19.029 12.21 17.009 12.765 14.169 14 C 12.419 14.761 12.073 15.923 11.474 16.511 C 10.875 17.1 10.077 17.1 8.18 17.25 C 1.777 17.25 -0.053 17 0.001 11.001 Z "
        />
      </StyledShape1>
      <StyledShape2>
        <AnimatedShape
          name="AloneShapes-2"
          originalWidth={430}
          originalHeight={430}
          fill={colors.peachMilk}
          paths={[
            ' M 1.632 5.84 L 11.893 0.914 C 13.38 0.2 15.225 0.799 16.01 2.25 L 21.141 11.737 C 21.925 13.188 21.355 14.946 19.868 15.66 L 9.607 20.586 C 8.12 21.3 6.275 20.701 5.49 19.25 L 0.359 9.763 C -0.425 8.312 0.145 6.554 1.632 5.84 Z ',
            ' M 0.831 13.726 L 4.603 2.987 C 5.15 1.43 6.878 0.549 8.459 1.02 L 18.795 4.1 C 20.376 4.572 21.216 6.218 20.669 7.774 L 16.897 18.513 C 16.35 20.07 14.622 20.951 13.041 20.48 L 2.705 17.4 C 1.124 16.928 0.284 15.282 0.831 13.726 Z ',
          ]}
        />
      </StyledShape2>
    </StyledContainer>
  </StyledWrapper>
);

export default AloneShapes;
