import styled from 'styled-components';
import { breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  padding: 3.35rem 0;
  position: relative;
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    right: 1.4rem;
    top: 5.8rem;
    transform: rotate(270deg);
    width: 46.5rem;
    height: 34.5rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    right: -12.1rem;
    top: 1rem;
    width: 43rem;
    height: 43rem;
  }
`;
